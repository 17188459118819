// Accessible image sprites (modificat):
// https://www.paciellogroup.com/blog/2012/08/notes-on-accessible-css-image-sprites/

function HCTest() {
    'use strict';

    var objDiv;
    var strColor;
    var objFlag;

    // reference to img element used to check if images are disabled
    objFlag = document.getElementById('flag');
    if (objFlag === null) {
        return;
    }

    // Create a test div
    objDiv = document.createElement('div');

    // Set its color style to something unusual
    objDiv.style.color = 'rgb(31,41,59)';

    // Attach to body so we can inspect it
    document.body.appendChild(objDiv);

    // Use standard means if available, otherwise use the IE methods

    strColor = document.defaultView ? document.defaultView.getComputedStyle(objDiv, null).color : objDiv.currentStyle.color;

    // get rid of extra spaces in result
    // strColor = strColor.replace(/ /g,"");

    // Delete the test DIV

    document.body.removeChild(objDiv);

    // Check if we got back what we set (strColor== ??) If not we are in high contrast mode
    // Use .offsetwidth and .readyState (for IE) to check if images are enabled
    // If either images are disabled or high contrast is enabled (or both) the CSS stylesheet link will not be added to the page and the visually hidden text will be displayed in place of the missing background image

    if (!(((objFlag.offsetWidth === 1 && objFlag.readyState === 'complete') || (objFlag.offsetWidth === 1 && objFlag.readyState === undefined)) && (strColor === 'rgb(31,41,59)' || strColor === 'rgb(31, 41, 59)'))) {

        var objHead = document.getElementsByTagName('head');
        var objCSS = objHead[0].appendChild(document.createElement('link'));
        objCSS.rel = 'stylesheet';
        objCSS.href = '/media/css/alt.css';
        // objCSS.type = 'text/css';
    }
}

// Safely initiate foundation now


// the rest of your code

$(window).on(function() {
    'use strict';

    // accessible image sprites
    HCTest();
    $(document).foundation();
});

// feature.js
if (feature.svg) {
    $('html').addClass('svg');
}

// mobile-nav
$('.menu-button').click(
    function() {
        if (!$('.main-nav').hasClass('open')) {
            $('.menu-wrapper')
                .addClass('is-visible')
                .removeClass('is-hidden')
                .removeClass('js-hidden');
            $('.main-nav').addClass('open');
            $('.caption').addClass('open-cap');
        } else {
            $('.menu-wrapper')
                .addClass('is-hidden')
                .addClass('js-hidden')
                .removeClass('is-visible');
            $('.main-nav').removeClass('open');
             $('.caption').removeClass('open-cap');
        }
        return false;
    }
);

$(document).ready(function() {

// -----------------
  // Smooth scroll top
  // -----------------

  if (window.matchMedia("(min-width: 64em)").matches) {
    $(window).scroll(function() {
      if ($(this).scrollTop() > 400) {
        $(".top").fadeIn();
      } else {
        $(".top").fadeOut();
      }
    });
    $(function() {
      $(
        'a[href*="#"]:not([href="#"],[href="#menu"],[href="#login-popup"],[href="#proyecto"],[href="#alimentacion"],.open-form a)'
      ).click(function() {
        if (
          location.pathname.replace(/^\//, "") ==
            this.pathname.replace(/^\//, "") &&
          location.hostname == this.hostname
        ) {
          var target = $(this.hash);
          target = target.length
            ? target
            : $("[name=" + this.hash.slice(1) + "]");
          if (target.length) {
            $("html, body").animate(
              {
                scrollTop: target.offset().top
              },
              1000
            );
            location.hash = this.hash.slice(1);
            return false;
          }
        }
      });
    });
  }

  //slider

  $(".slider-equip").slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
 //sticky header
    var stickyNavTop = $('.main-header').offset().top;
        var stickyNav = function(){
          var scrollTop = $(window).scrollTop();
          if (scrollTop > stickyNavTop) {
              $('.main-header').addClass('sticky');
          } else {
              $('.main-header').removeClass('sticky');
          }
      };

      stickyNav();
      $(window).scroll(function() {
        stickyNav();
      });

  //al validar formulari fer scroll tingui en compte sticky header
  $("input,textarea").focus(function(){
      var elementOffset = $(this).offset().top,
      scrollTop     = $(window).scrollTop(),
      distance      = (elementOffset - scrollTop);
      var label = $(this).closest('div').find('label');
      //modicar classes depent de projecte
      var addSpace = $('.main-header.sticky').height() + label.height() + 10 - distance;

      if(addSpace > 0)
      {
          var y = $(window).scrollTop();
          $(window).scrollTop(y-addSpace);
      }
  });

});
